import { Constants } from "@/constants";
import Vue from "vue";
import store from "@/store/store";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const LocaleComponent = {
  render(h: any) {
    return h("router-view");
  },
};

export const ROUTERS = {
  ACCESS_CONTROL: {
    LOGIN: {
      PATH: "/login",
      NAME: "Acesse sua conta",
      ICON: "login",
    },
    LOGOUT: {
      PATH: "/logout",
      NAME: "Acesse sua conta",
      ICON: "login",
    },
    EXPIRED_SESSION: {
      PATH: "/expiredSession",
      NAME: "Sessão Expirada",
      ICON: "update",
    },
    NEWACCOUNT: {
      NEW_PARTNER_ACCOUNT: {
        PATH: "/newBusinessUnit",
        NAME: "Criar Perfil Comercial",
        ICON: "person_add",
      },

      NEW_CLIENT_ACCOUNT: {
        PATH: "/newClientAccount",
        NAME: "Criar conta",
        ICON: "person_add",
      },
      NEW_CLIENT_PARTNER_ACCOUNT: {
        PATH: "/newAccount",
        NAME: "Criar Perfil Comercial",
        ICON: "person_add",
      },
    },
  },
  PROFILE: {
    CLIENT: {
      PATH: "/myProfile",
      NAME: "Meu Perfil",
      ICON: "person",
    },
    BUSINESS_UNIT: {
      PATH: "/partner_profile",
      NAME: "Configurações da empresa",
      ICON: "business",
    },
    PET: {
      PATH: "/mypet_profile/:searchContent",
      NAME: "/mypet_profile/:searchContent",
      ICON: "pets",
    },
    USER_PUBLIC_PROFILE: {
      PATH: "/user/:searchContent",
      NAME: "/user/:searchContent",
      ICON: "account_circle",
    },
  },
  ADMINISTRATIVE_DASHBOARD: {
    PATH: "/dashboard/",
    NAME: "Painel de Controle",
    ICON: "business",
  },
  BUSINESS_UNIT_STORE: {
    PATH: "/store/:searchContent",
    NAME: "/store/:searchContent",
    ICON: "business",
  },
  HOME: {
    DEFAULT: {
      PATH: "/marketPet",
      NAME: "Início",
      ICON: "home",
    },
  },
  TIMELINE: {
    DEFAULT: {
      PATH: "/",
      NAME: "Start",
      ICON: "view_timeline",
      IMG: "./vectors/several/foryou.svg",
    },
  },
  GROUPS: {
    DEFAULT: {
      PATH: "/groups",
      NAME: "Grupos",
      ICON: "groups",
    },
    DETAILS: {
      PATH: "/group/:searchContent",
      NAME: "/group/:searchContent",
      ICON: "groups",
    },
  },
  FAVORITES: {
    DEFAULT: {
      PATH: "/favorites",
      NAME: "Favoritos",
      ICON: "favorite",
    },
  },
  ONLINE_DOCTOR: {
    DEFAULT: {
      PATH: "/onlineDoctor",
      NAME: "Doutor Online",
      ICON: "local_hospital",
      IMG: "",
    },
    APPOINTMENT: {
      PATH: "/onlineDoctor",
      NAME: "Marcar consulta",
      ICON: "local_hospital",
    },
    SCHEDULES: {
      PATH: "/mySchedules",
      NAME: "Minha Agenda",
      ICON: "alarm",
    },
  },
  BUSINESS_UNIT_PRODUCTS: {
    DEFAULT: {
      PATH: "/partnerProducts",
      NAME: "Mix de Produtos",
      ICON: "inventory_2",
    },
  },
  CATEGORY_MANAGER: {
    DEFAULT: {
      PATH: "/categoryManager",
      NAME: "Gestão de Categorias e Grupos",
      ICON: "inventory_2",
    },
  },
  PRODUCT_MANAGER: {
    DEFAULT: {
      PATH: "/productManager",
      NAME: "Gestão de Produtos",
      ICON: "inventory_2",
    },
  },
  SERVICE_MANAGER: {
    DEFAULT: {
      PATH: "/serviceManager",
      NAME: "Gestão de Serviços",
      ICON: "vaccines",
    },
    FIND_SERVICE: {
      PATH: "/findService",
      NAME: "Pesquisa de Serviços",
      ICON: "vaccines",
    },
  },
  ADMINISTRATIVE_USERS: {
    DEFAULT: {
      PATH: "/administrativeUsers",
      NAME: "Usuários Administrativos",
      ICON: "manage_accounts",
    },
  },
  NEW_ADMINISTRATIVE_USER: {
    DEFAULT: {
      PATH: "/newUser/:requestId",
      NAME: "/newUser/:requestId",
      ICON: "user",
    },
  },
  PARTNER_SERVICES: {
    DEFAULT: {
      PATH: "/partnerServices",
      NAME: "Serviços",
      ICON: "vaccines",
    },
  },
  PURCHASES: {
    DEFAULT: {
      PATH: "/mypurchases",
      NAME: "Histórico de compras",
      ICON: "shopping_cart",
    },
  },
  SALES: {
    DEFAULT: {
      PATH: "/mySales",
      NAME: "Painel de Vendas",
      ICON: "attach_money",
    },
  },
  MARKETING: {
    DEFAULT: {
      PATH: "/marketing",
      NAME: "Campanhas de Marketing e Promoções",
      ICON: "campaign",
    },
  },
  MESSAGES: {
    DEFAULT: {
      PATH: "/messages",
      NAME: "Mensagens",
      ICON: "mark_chat_unread",
    },
  },
  SEARCH: {
    DEFAULT: {
      PATH: "/searchContent/:searchContent/:searchType",
      NAME: "/searchContent/:searchContent/:searchType",
      ICON: "pets",
    },
    CATEGORY: {
      PATH: "/category/:searchContent/:t",
      NAME: "/category/:searchContent/:t",
      ICON: "pets",
    },

    BRAND: {
      PATH: "/brand/:searchContent",
      NAME: "/brand/:searchContent",
      ICON: "pets",
    },
  },
  SHOPCART: {
    DEFAULT: {
      PATH: "/shopCart",
      NAME: "Meu Carrinho de Compras",
      ICON: "shopping_cart",
    },
  },

  INSTITUTIONAL: {
    ABOUT: {
      DEFAULT: {
        PATH: "/whois",
        NAME: "Quem é PetAoLado.com",
        ICON: "pets",
      },
    },
    BELIEVE: {
      DEFAULT: {
        PATH: "/believe",
        NAME: "Cremos",
        ICON: "question_answer",
      },
    },
    THE_SW: {
      DEFAULT: {
        PATH: "/the_sw",
        NAME: "A SoluftWare///",
        ICON: "question_answer",
      },
    },
  },

  PET_ACADEMY: {
    DEFAULT: {
      PATH: "/pet_academy",
      NAME: "PetAoLado Academy",
      ICON: "",
    },
    NEW_USER_PROFILE: {
      PATH: "/newuserprofile",
      NAME: "Criando um perfil de usuário",
      ICON: "",
    },
    CONFIG_MY_USER_PROFILE: {
      PATH: "/configmyprofile",
      NAME: "Configurando meu perfil de usuário",
      ICON: "",
    },
    NEW_COMMERCIAL_PROFILE: {
      PATH: "/newcommercialprofile",
      NAME: "Criando um perfil comercial",
      ICON: "",
    },
    CONFIG_MY_STORE: {
      PATH: "/configmystore",
      NAME: "Configurando minha loja",
      ICON: "",
    },
    SELECT_PRODUCTS: {
      PATH: "/selectproducts",
      NAME: "Selecionando produtos",
      ICON: "",
    },
    SELECT_SERVICES: {
      PATH: "/selectservices",
      NAME: "Selecionando serviços",
      ICON: "",
    },
    CONFIG_MY_DELIVERY_SERVICE: {
      PATH: "/configmydeliveryservice",
      NAME: "Configurando serviço de entregas",
      ICON: "",
    },
    ACTIVATE_MY_STORE: {
      PATH: "/activatemystore",
      NAME: "Ativando minha loja",
      ICON: "",
    },
    SHARE_CONTENTS: {
      PATH: "/sharecontents",
      NAME: "Publicando conteúdos",
      ICON: "",
    },
    GENERAL: {
      PATH: "/academy/class",
      NAME: "Classromm",
      ICON: "",
    },
  },
  NOTIFICATIONS_CENTER: {
    DEFAULT: {
      PATH: "/notifications_center",
      NAME: "Central de Notificações",
      ICON: "schedules",
    },
  },

  ALL_MYORDERS: {
    DEFAULT: {
      PATH: "/all_myorders",
      NAME: "Central de Pedidos",
      ICON: "",
    },
  },

  CONTACTUS: {
    DEFAULT: {
      PATH: "/contactUs",
      NAME: "Fale com a PetAoLado.com",
      ICON: "question_answer",
    },
  },
  PROBLEM_SOLUTION: {
    DEFAULT: {
      PATH: "/problemSolution",
      NAME: "Solução de Problemas",
      ICON: "question_answer",
    },
  },
  API: {
    DEFAULT: {
      PATH: "https://localhost:5003/swagger/index.html",
      NAME: "API",
      ICON: "question_answer",
    },
  },
  ACCESS_MANAGER: {
    DEFAULT: {
      PATH: "https://localhost:5001/swagger/index.html",
      NAME: "Documentação",
      ICON: "question_answer",
    },
  },
  SOCIAL: {
    TWITTER: {
      PATH: "",
      NAME: "Twitter",
      ICON: "person",
    },
    FACEBOOK: {
      PATH: "https://www.facebook.com/PetAoLado/",
      NAME: "Facebook",
      ICON: "business",
    },
    INSTAGRAN: {
      PATH: "https://www.instagram.com/petaolado/",
      NAME: "Instagran",
      ICON: "pets",
    },
    TIKTOK: {
      PATH: "https://www.tiktok.com/@petaolado",
      NAME: "TikTok",
      ICON: "pets",
    },
  },

  POLICIES: {
    PRIVACY: {
      PATH: "/privacity",
      NAME: "Privacidade",
      ICON: "",
    },
    COOKIES: {
      PATH: "/cookies",
      NAME: "Cookies",
      ICON: "",
    },
  },

  FOR_YOU: {
    MY_PETS: {
      PATH: "/myPets",
      NAME: "Meus amiguinhos",
      ICON: "pets",
    },
  },

  FOOTER_MENU: {
    WORK: {
      PATH: "/workwithus",
      NAME: "Trabalhe conosco",
      ICON: "person",
    },
    CONDITIONS: {
      PATH: "/conditions",
      NAME: "Termos e Condições",
      ICON: "business",
    },
    PRIVACITY: {
      PATH: "/privacity",
      NAME: "Política de Privacidade PetAoLado.com",
      ICON: "pets",
    },
  },
  CHECKOUT: {
    PRODUCT_DETAILS: {
      PATH: "/productDetails/:searchContent/:businessUnitId",
      NAME: "/productDetails/:searchContent/:businessUnitId",
      ICON: "point_of_sale",
    },
    SHOPKEEPERS: {
      PATH: "/productShopkeepers/:searchContent/:businessUnitId?/:initialDistance?",
      NAME: "/productShopkeepers/:searchContent/:businessUnitId?/:initialDistance?",
      ICON: "point_of_sale",
    },
    WITH_SHOPKEEPERS: {
      PATH: "/Shopkeepers/:searchContent/:businessUnitId?/:initialDistance?",
      NAME: "/Shopkeepers/:searchContent/:businessUnitId?/:initialDistance?",
      ICON: "point_of_sale",
    },
    WITHOUT_SHOPKEEPERS: {
      PATH: "/product/:searchContent/:businessUnitId?",
      NAME: "/product/:searchContent/:businessUnitId?",
      ICON: "point_of_sale",
    },
    SERVICE_SHOPKEEPERS: {
      PATH: "/ServiceShopkeepers/:searchContent/:initialDistance?",
      NAME: "/ServiceShopkeepers/:searchContent/:initialDistance?",
      ICON: "point_of_sale",
    },
    ORDER_CONFIRMATION: {
      PATH: "/orderConfirmation/:orderNumber?",
      NAME: "/orderConfirmation/:orderNumber?",
      ICON: "point_of_sale",
    },
  },
  OFFICIAL_STORES: {
    DEFAULT: {
      PATH: "/officialStores",
      NAME: "Lojas Oficiais",
      ICON: "store",
    },
  },
  MAIL_CONFIRMATION: {
    DEFAULT: {
      PATH: "/MailConfirmation/:userId",
      NAME: "/MailConfirmation/:userId",
      ICON: "mail",
    },
  },
  PASSWORD_RECOVERY: {
    DEFAULT: {
      PATH: "/passwordRecovery/:userId",
      NAME: "/passwordRecovery/:userId",
      ICON: "mail",
    },
  },
  SERVICE_SCHEDULE: {
    DEFAULT: {
      PATH: "/serviceSchedule/:businessUnitNickname/:serviceDescription",
      NAME: "/serviceSchedule/:businessUnitNickname/:serviceDescription",
      ICON: "more_time",
    },
  },
  DOCTORS_ATTENDANTS: {
    DEFAULT: {
      PATH: "/doctorsAndAttendants",
      NAME: "Doutores e Atendentes",
      ICON: "accessibility_new",
    },
  },
  MY_SCHEDULES: {
    DEFAULT: {
      PATH: "/mySchedules",
      NAME: "Minha Agenda",
      ICON: "alarm",
    },
  },
  ADOPTIONS: {
    ADOPET: {
      PATH: "/adopet",
      NAME: "AdoPet",
      ICON: "diversity_3",
      IMG: "./vectors/several/AdoPet.svg",
    },
    FOR_ADOPTION_USER: {
      PATH: "/forAdoptionUser",
      NAME: "Meus animais para Adoção",
      ICON: "pets",
    },
    FOR_ADOPTION: {
      PATH: "/forAdoption",
      NAME: "Animais para Adoção",
      ICON: "pets",
    },
    ANIMAL_DETAILS: {
      PATH: "/details",
      NAME: "Mais detalhes do amiguinho",
      ICON: "pets",
    },
  },

  ONLINE_CALL: {
    DEFAULT: {
      PATH: "/onlineCall/:meetingId",
      NAME: "/onlineCall/:meetingId",
      ICON: "public",
    },
  },

  PARAMETERS_MANAGER: {
    DEFAULT: {
      PATH: "/parametersManager",
      NAME: "Gestão de Parâmetros",
      ICON: "settings",
    },
  },

  SERVICES: {
    DEFAULT: {
      PATH: "/services",
      NAME: "Serviços",
      ICON: "settings",
    },
  },

  AI: {
    PRODUCT_SUGESTION: {
      PATH: "/productSugestion/:eanCode",
      NAME: "/productSugestion/:eanCode",
      ICON: "settings",
    },
  },
  PAGENOTFOUND: {
    DEFAULT: {
      PATH: "/pagenotfound",
      NAME: "/pagenotfound",
      ICON: "mail",
    },
  },
  PAYMENT_MANAGEMENT: {
    DEFAULT: {
      PATH: "/paymentBusinessUnit",
      NAME: "Gestão de Mensalidades",
      ICON: "mail",
    },
  },
  INDUSTRY_RELATIONSHIP: {
    DEFAULT: {
      PATH: "/industry",
      NAME: "Cadastro de Relacionamento de Indústrias e Produtos",
      ICON: "factory",
    },
  },
  INDUSTRY_ACTIVATION: {
    DEFAULT: {
      PATH: "/industryActivation",
      NAME: "Ativação de Indústrias e Distribuidores",
      ICON: "factory",
    },
  },

  FOR_YOUR_PET: {
    ADVERTISING: {
      PATH: "/advertising",
      NAME: "Anúncios",
      ICON: "",
    },
    PET_COURSES: {
      PATH: "/ead",
      NAME: "EAD",
      ICON: "",
    },
    PET_DATING: {
      PATH: "/pet_dating",
      NAME: "Pet Namoro",
      ICON: "",
    },
    PET_MEDICINS: {
      PATH: "/medicins",
      NAME: "Prontuário Eletrônico",
      ICON: "",
    },
    PET_WORK: {
      PATH: "/petwork",
      NAME: "PetWork",
      ICON: "",
    },
  },
};

const routes: Array<RouteConfig> = [
  {
    path: ROUTERS.ACCESS_CONTROL.LOGIN.PATH,
    name: ROUTERS.ACCESS_CONTROL.LOGIN.NAME,
    component: () => import("@/views/account/login"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.ACCESS_CONTROL.LOGOUT.PATH,
    name: ROUTERS.ACCESS_CONTROL.LOGOUT.NAME,
    component: () => import("@/views/account/logout"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.ACCESS_CONTROL.NEWACCOUNT.NEW_PARTNER_ACCOUNT.PATH,
    name: ROUTERS.ACCESS_CONTROL.NEWACCOUNT.NEW_PARTNER_ACCOUNT.NAME,
    component: () => import("@/views/account/newBusinessUnitAccount"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.ACCESS_CONTROL.EXPIRED_SESSION.PATH,
    name: ROUTERS.ACCESS_CONTROL.EXPIRED_SESSION.NAME,
    component: () => import("@/views/account/expiredSession"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.ACCESS_CONTROL.NEWACCOUNT.NEW_CLIENT_ACCOUNT.PATH,
    name: ROUTERS.ACCESS_CONTROL.NEWACCOUNT.NEW_CLIENT_ACCOUNT.NAME,
    component: () => import("@/views/account/newUserAccount"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.ACCESS_CONTROL.NEWACCOUNT.NEW_CLIENT_PARTNER_ACCOUNT.PATH,
    name: ROUTERS.ACCESS_CONTROL.NEWACCOUNT.NEW_CLIENT_PARTNER_ACCOUNT.NAME,
    component: () => import("@/views/account/newCommercialUserAccount"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.PROFILE.CLIENT.PATH,
    name: ROUTERS.PROFILE.CLIENT.NAME,
    component: () => import("@/views/profile/myProfile"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.PROFILE.BUSINESS_UNIT.PATH,
    name: ROUTERS.PROFILE.BUSINESS_UNIT.NAME,
    component: () => import("@/views/businessUnits/businessUnitProfile"),
    meta: {
      transition: "fade-in-up",
      roles: [Constants.Enums.PERSONTYPE.BUSINESS_UNIT],
    },
  },
  {
    path: ROUTERS.PROFILE.PET.PATH,
    name: ROUTERS.PROFILE.PET.NAME,
    component: () => import("@/views/pets/myPetProfile"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.PROFILE.USER_PUBLIC_PROFILE.PATH,
    name: ROUTERS.PROFILE.USER_PUBLIC_PROFILE.NAME,
    component: () => import("@/views/profile/userPublicProfile"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.HOME.DEFAULT.PATH,
    name: ROUTERS.HOME.DEFAULT.NAME,
    component: () => import("@/views/marketPet/marketPet"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.TIMELINE.DEFAULT.PATH,
    name: ROUTERS.TIMELINE.DEFAULT.NAME,
    component: () => import("@/views/feed/timeline"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
      ],
    },
  },

  {
    path: ROUTERS.FAVORITES.DEFAULT.PATH,
    name: ROUTERS.FAVORITES.DEFAULT.NAME,
    component: () => import("@/views/favorites/favorites"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.ONLINE_DOCTOR.DEFAULT.PATH,
    name: ROUTERS.ONLINE_DOCTOR.DEFAULT.NAME,
    component: () => import("@/views/doctorOnline/doctorOnline"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.ADMINISTRATIVE_USERS.DEFAULT.PATH,
    name: ROUTERS.ADMINISTRATIVE_USERS.DEFAULT.NAME,
    component: () =>
      import(
        "@/views/businessUnits/components/administrativeUsers/administrativeUsers"
      ),
    meta: {
      transition: "fade-in-up",
      roles: [Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER],
    },
  },
  {
    path: ROUTERS.NEW_ADMINISTRATIVE_USER.DEFAULT.PATH,
    name: ROUTERS.NEW_ADMINISTRATIVE_USER.DEFAULT.NAME,
    component: () =>
      import(
        "@/views/businessUnits/components/administrativeUsers/components/newAdmnistrativeUser"
      ),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },

  {
    path: ROUTERS.GROUPS.DEFAULT.PATH,
    name: ROUTERS.GROUPS.DEFAULT.NAME,
    component: () => import("@/views/groups/groupsList"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.GROUPS.DETAILS.PATH,
    name: ROUTERS.GROUPS.DETAILS.NAME,
    component: () => import("@/views/groups/groupDetails"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.CATEGORY_MANAGER.DEFAULT.PATH,
    name: ROUTERS.CATEGORY_MANAGER.DEFAULT.NAME,
    component: () => import("@/views/category/categoryManager"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.PRODUCT_MANAGER.DEFAULT.PATH,
    name: ROUTERS.PRODUCT_MANAGER.DEFAULT.NAME,
    component: () => import("@/views/products/productManager"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        // Constants.Enums.PERSONTYPE.NO_USER,
        // Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.SERVICE_MANAGER.DEFAULT.PATH,
    name: ROUTERS.SERVICE_MANAGER.DEFAULT.NAME,
    component: () =>
      import("@/views/servicesManager/businessUnitServicesManager"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.SERVICE_MANAGER.FIND_SERVICE.PATH,
    name: ROUTERS.SERVICE_MANAGER.FIND_SERVICE.NAME,
    component: () => import("@/views/servicesManager/findService"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.BUSINESS_UNIT_PRODUCTS.DEFAULT.PATH,
    name: ROUTERS.BUSINESS_UNIT_PRODUCTS.DEFAULT.NAME,
    component: () => import("@/views/businessUnits/businessUnitProducts"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.PARTNER_SERVICES.DEFAULT.PATH,
    name: ROUTERS.PARTNER_SERVICES.DEFAULT.NAME,
    component: () => import("@/views/servicesManager/businessUnitServices"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.PURCHASES.DEFAULT.PATH,
    name: ROUTERS.PURCHASES.DEFAULT.NAME,
    component: () => import("@/views/saleSystem/myPurchases"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.SALES.DEFAULT.PATH,
    name: ROUTERS.SALES.DEFAULT.NAME,
    component: () => import("@/views/saleSystem/mySales"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
      ],
    },
  },
  {
    path: ROUTERS.MESSAGES.DEFAULT.PATH,
    name: ROUTERS.MESSAGES.DEFAULT.NAME,
    component: () => import("@/views/chat/chat"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.SEARCH.DEFAULT.PATH,
    name: ROUTERS.SEARCH.DEFAULT.NAME,
    component: () =>
      import("@/views/marketPet/components/appSearch/searchResult"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.SEARCH.CATEGORY.PATH,
    name: ROUTERS.SEARCH.CATEGORY.NAME,
    component: () =>
      import("@/views/marketPet/components/appSearch/searchCategory"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.SEARCH.BRAND.PATH,
    name: ROUTERS.SEARCH.BRAND.NAME,
    component: () =>
      import("@/views/marketPet/components/appSearch/brandResult"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.MARKETING.DEFAULT.PATH,
    name: ROUTERS.MARKETING.DEFAULT.NAME,
    component: () => import("@/views/marketing/marketing"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.SHOPCART.DEFAULT.PATH,
    name: ROUTERS.SHOPCART.DEFAULT.NAME,
    component: () => import("@/views/saleSystem/myShopCart"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.INSTITUTIONAL.ABOUT.DEFAULT.PATH,
    name: ROUTERS.INSTITUTIONAL.ABOUT.DEFAULT.NAME,
    component: () => import("@/views/several/about"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.CONTACTUS.DEFAULT.PATH,
    name: ROUTERS.CONTACTUS.DEFAULT.NAME,
    component: () => import("@/views/several/contactUs"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.INSTITUTIONAL.THE_SW.DEFAULT.PATH,
    name: ROUTERS.INSTITUTIONAL.THE_SW.DEFAULT.NAME,
    component: () => import("@/views/several/the_sw"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },

  {
    path: ROUTERS.INSTITUTIONAL.BELIEVE.DEFAULT.PATH,
    name: ROUTERS.INSTITUTIONAL.BELIEVE.DEFAULT.NAME,
    component: () => import("@/views/several/believe"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.PROBLEM_SOLUTION.DEFAULT.PATH,
    name: ROUTERS.PROBLEM_SOLUTION.DEFAULT.NAME,
    component: () => import("@/views/several/problemSolution"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.FOOTER_MENU.WORK.PATH,
    name: ROUTERS.FOOTER_MENU.WORK.NAME,
    component: () => import("@/views/several/workWithUs"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.FOOTER_MENU.CONDITIONS.PATH,
    name: ROUTERS.FOOTER_MENU.CONDITIONS.NAME,
    component: () => import("@/views/several/conditions"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.FOOTER_MENU.PRIVACITY.PATH,
    name: ROUTERS.FOOTER_MENU.PRIVACITY.NAME,
    component: () => import("@/views/several/privacity"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.CHECKOUT.PRODUCT_DETAILS.PATH,
    name: ROUTERS.CHECKOUT.PRODUCT_DETAILS.NAME,
    component: () => import("@/views/products/productDetails"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.CHECKOUT.SHOPKEEPERS.PATH,
    name: ROUTERS.CHECKOUT.SHOPKEEPERS.NAME,
    component: () => import("@/views/products/productShopkeepers"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },

  {
    path: ROUTERS.CHECKOUT.WITH_SHOPKEEPERS.PATH,
    name: ROUTERS.CHECKOUT.WITH_SHOPKEEPERS.NAME,
    component: () => import("@/views/saleSystem/checkout/shopkeepers"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.CHECKOUT.SERVICE_SHOPKEEPERS.PATH,
    name: ROUTERS.CHECKOUT.SERVICE_SHOPKEEPERS.NAME,
    component: () => import("@/views/saleSystem/checkout/serviceShopkeepers"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.CHECKOUT.WITHOUT_SHOPKEEPERS.PATH,
    name: ROUTERS.CHECKOUT.WITHOUT_SHOPKEEPERS.NAME,
    component: () => import("@/views/saleSystem/checkout/noShopkeepers"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.CHECKOUT.ORDER_CONFIRMATION.PATH,
    name: ROUTERS.CHECKOUT.ORDER_CONFIRMATION.NAME,
    component: () => import("@/views/saleSystem/orderConfirmation"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },

  {
    path: ROUTERS.BUSINESS_UNIT_STORE.PATH,
    name: ROUTERS.BUSINESS_UNIT_STORE.NAME,
    component: () => import("@/views/businessUnits/businessUnitStore"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.OFFICIAL_STORES.DEFAULT.PATH,
    name: ROUTERS.OFFICIAL_STORES.DEFAULT.NAME,
    component: () => import("@/views/businessUnits/officialStores"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.MAIL_CONFIRMATION.DEFAULT.PATH,
    name: ROUTERS.MAIL_CONFIRMATION.DEFAULT.NAME,
    component: () => import("@/views/account/mailConfirmation"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },

  {
    path: ROUTERS.PASSWORD_RECOVERY.DEFAULT.PATH,
    name: ROUTERS.PASSWORD_RECOVERY.DEFAULT.NAME,
    component: () => import("@/views/account/passwordRecovery"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.SERVICE_SCHEDULE.DEFAULT.PATH,
    name: ROUTERS.SERVICE_SCHEDULE.DEFAULT.NAME,
    component: () => import("@/views/servicesManager/serviceSchedule"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.DOCTORS_ATTENDANTS.DEFAULT.PATH,
    name: ROUTERS.DOCTORS_ATTENDANTS.DEFAULT.NAME,
    component: () => import("@/views/attendants/attendantsManager"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },

  {
    path: ROUTERS.MY_SCHEDULES.DEFAULT.PATH,
    name: ROUTERS.MY_SCHEDULES.DEFAULT.NAME,
    component: () => import("@/views/servicesManager/mySchedules"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.ADOPTIONS.ADOPET.PATH,
    name: ROUTERS.ADOPTIONS.ADOPET.NAME,
    component: () => import("@/views/forAdoption/adoption"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.ADOPTIONS.FOR_ADOPTION_USER.PATH,
    name: ROUTERS.ADOPTIONS.FOR_ADOPTION_USER.NAME,
    component: () => import("@/views/forAdoption/forAdoptionUser"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.ADOPTIONS.FOR_ADOPTION.PATH,
    name: ROUTERS.ADOPTIONS.FOR_ADOPTION.NAME,
    component: () => import("@/views/forAdoption/forAdoption"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.ADOPTIONS.ANIMAL_DETAILS.PATH,
    name: ROUTERS.ADOPTIONS.ANIMAL_DETAILS.NAME,
    component: () => import("@/views/forAdoption/animalDetails"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },

  {
    path: ROUTERS.ONLINE_CALL.DEFAULT.PATH,
    name: ROUTERS.ONLINE_CALL.DEFAULT.NAME,
    component: () => import("@/views/doctorOnline/doctorOnlineCall"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.PARAMETERS_MANAGER.DEFAULT.PATH,
    name: ROUTERS.PARAMETERS_MANAGER.DEFAULT.NAME,
    component: () => import("@/views/parameters/parametersManager"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
      ],
    },
  },
  {
    path: ROUTERS.AI.PRODUCT_SUGESTION.PATH,
    name: ROUTERS.AI.PRODUCT_SUGESTION.NAME,
    component: () => import("@/views/products/AI/productSugestion"),
    meta: {
      transition: "fade-in-up",
      roles: [Constants.Enums.PERSONTYPE.BUSINESS_UNIT],
    },
  },
  {
    path: ROUTERS.PET_ACADEMY.DEFAULT.PATH,
    name: ROUTERS.PET_ACADEMY.DEFAULT.NAME,
    component: () => import("@/views/petAcademy/petAcademy"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },

  {
    path: ROUTERS.PET_ACADEMY.NEW_USER_PROFILE.PATH,
    name: ROUTERS.PET_ACADEMY.NEW_USER_PROFILE.NAME,
    component: () => import("@/views/petAcademy/trails/trailNewUserProfile"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.PET_ACADEMY.CONFIG_MY_USER_PROFILE.PATH,
    name: ROUTERS.PET_ACADEMY.CONFIG_MY_USER_PROFILE.NAME,
    component: () =>
      import("@/views/petAcademy/trails/trailConfigMyUserProfile"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.PET_ACADEMY.NEW_COMMERCIAL_PROFILE.PATH,
    name: ROUTERS.PET_ACADEMY.NEW_COMMERCIAL_PROFILE.NAME,
    component: () =>
      import("@/views/petAcademy/trails/trailNewCommercialProfile"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.PET_ACADEMY.CONFIG_MY_STORE.PATH,
    name: ROUTERS.PET_ACADEMY.CONFIG_MY_STORE.NAME,
    component: () => import("@/views/petAcademy/trails/trailConfigMyStore"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.PET_ACADEMY.SELECT_PRODUCTS.PATH,
    name: ROUTERS.PET_ACADEMY.SELECT_PRODUCTS.NAME,
    component: () => import("@/views/petAcademy/trails/trailSelectProducts"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.PET_ACADEMY.SELECT_SERVICES.PATH,
    name: ROUTERS.PET_ACADEMY.SELECT_SERVICES.NAME,
    component: () => import("@/views/petAcademy/trails/trailSelectServices"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.PET_ACADEMY.CONFIG_MY_DELIVERY_SERVICE.PATH,
    name: ROUTERS.PET_ACADEMY.CONFIG_MY_DELIVERY_SERVICE.NAME,
    component: () =>
      import("@/views/petAcademy/trails/trailConfigMyDeliveryService"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.PET_ACADEMY.ACTIVATE_MY_STORE.PATH,
    name: ROUTERS.PET_ACADEMY.ACTIVATE_MY_STORE.NAME,
    component: () => import("@/views/petAcademy/trails/trailActivateMyStore"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.PET_ACADEMY.SHARE_CONTENTS.PATH,
    name: ROUTERS.PET_ACADEMY.SHARE_CONTENTS.NAME,
    component: () => import("@/views/petAcademy/trails/trailShareContents"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.PET_ACADEMY.GENERAL.PATH,
    name: ROUTERS.PET_ACADEMY.SHARE_CONTENTS.NAME,
    component: () => import("@/views/petAcademy/trails/trailGeneral"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.PAYMENT_MANAGEMENT.DEFAULT.PATH,
    name: ROUTERS.PAYMENT_MANAGEMENT.DEFAULT.NAME,
    component: () => import("@/views/paymentManagement/paymentBusinessUnit"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  /*
  GENERAL: {
      PATH: "/academy/class",
      NAME: "Publicando conteúdos",
      ICON: "",
    },
  */
  {
    path: "*",
    name: ROUTERS.PAGENOTFOUND.DEFAULT.NAME,
    component: () => import("@/views/several/pageNotFound"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },

  {
    path: ROUTERS.ADMINISTRATIVE_DASHBOARD.PATH,
    name: ROUTERS.ADMINISTRATIVE_DASHBOARD.NAME,
    component: () =>
      import("@/views/administrativeDashboard/administrativeDashboard"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.INDUSTRY_RELATIONSHIP.DEFAULT.PATH,
    name: ROUTERS.INDUSTRY_RELATIONSHIP.DEFAULT.NAME,
    component: () => import("@/views/industry/industryRelationship"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },

  {
    path: ROUTERS.INDUSTRY_ACTIVATION.DEFAULT.PATH,
    name: ROUTERS.INDUSTRY_ACTIVATION.DEFAULT.NAME,
    component: () => import("@/views/industry/industryActivation"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.FOR_YOUR_PET.ADVERTISING.PATH,
    name: ROUTERS.FOR_YOUR_PET.ADVERTISING.NAME,
    component: () =>
      import(
        "@/views/marketPet/components/forYourPet/content/petAdvertisingDetails"
      ),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.FOR_YOUR_PET.PET_COURSES.PATH,
    name: ROUTERS.FOR_YOUR_PET.PET_COURSES.NAME,
    component: () =>
      import(
        "@/views/marketPet/components/forYourPet/content/petCoursesDetails"
      ),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },

  {
    path: ROUTERS.FOR_YOUR_PET.PET_DATING.PATH,
    name: ROUTERS.FOR_YOUR_PET.PET_DATING.NAME,
    component: () =>
      import(
        "@/views/marketPet/components/forYourPet/content/petDatingDetails"
      ),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.FOR_YOUR_PET.PET_MEDICINS.PATH,
    name: ROUTERS.FOR_YOUR_PET.PET_MEDICINS.NAME,
    component: () =>
      import(
        "@/views/marketPet/components/forYourPet/content/petMedicinsDetails"
      ),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
  {
    path: ROUTERS.FOR_YOUR_PET.PET_WORK.PATH,
    name: ROUTERS.FOR_YOUR_PET.PET_WORK.NAME,
    component: () => import("@/views/marketPet/components/petWork/petWork"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },

  {
    path: ROUTERS.NOTIFICATIONS_CENTER.DEFAULT.PATH,
    name: ROUTERS.NOTIFICATIONS_CENTER.DEFAULT.NAME,
    component: () => import("@/views/notifications/notificationsCenter"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },

  {
    path: ROUTERS.ALL_MYORDERS.DEFAULT.PATH,
    name: ROUTERS.ALL_MYORDERS.DEFAULT.NAME,
    component: () => import("@/views/marketPet/components/myOrders/allMyOrders"),
    meta: {
      transition: "fade-in-up",
      roles: [
        Constants.Enums.PERSONTYPE.BUSINESS_UNIT,
        Constants.Enums.PERSONTYPE.NO_USER,
        Constants.Enums.PERSONTYPE.CLIENT,
        Constants.Enums.PERSONTYPE.ADMINISTRATIVE_USER,
      ],
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (store.getters.currentSessionUser) {
    const PersonType = store.getters.currentSessionUser;
    const allowedRoles = (to.meta as { roles: string[] }).roles || [];
    const userRole = PersonType.personType;
    if (allowedRoles.indexOf(userRole) === -1) {
      // o usuário não tem a role apropriada para acessar a rota
      next("/unauthorized"); // redirecionar para uma página de erro ou login
    } else {
      // o usuário tem a role apropriada para acessar a rota
      next();
    }
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("jwt") == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      let content = localStorage.getItem("user");

      if (content != null) {
        let user = JSON.parse(content);
        if (to.matched.some((record) => record.meta.is_admin)) {
          if (user.is_admin == 1) {
            next();
          } else {
            next({ name: "home" });
          }
        } else {
          next();
        }
      }
    }
  } else {
    next();
  }
});

export default router;
