export namespace Constants.Enums {
  export const USERSTATUS = {
    Inactive: 0,
    PendingActivate: 1,
    Active: 2,
  };

  export const BUSINESSUNITSTATUS = {
    INACTIVE: 0,
    PENDING_ACTIVATE: 1,
    ACTIVE: 2,
    PENDING_PAYMENT: 3,
  };

  export const BUSINESSUNITSTATUS_LABELS = [
    { id: 0, description: "Inativa" },
    { id: 1, description: "Ativação Pendente" },
    { id: 2, description: "Ativa" },
    { id: 3, description: "Desativada por Pagamento Pendente" },
  ];

  export const BUSINESSUNITTYPE = {
    SHOPKEEPER: 0,
    DISTRIBUCTOR: 1,
    INDUSTRY: 2,
  };

  export const MONTHLYPAYMENTSTATUS_LABELS = [
    { id: 0, description: "Paga" },
    { id: 1, description: "Vencimento em até 5 dias" },
    { id: 2, description: "Vencida" },
  ];

  export const BUSINESSUNIT_MEMBERSHIP_PLAN_STATUS = {
    INACTIVE: 0,
    PENDING_ACTIVATE: 1,
    ACTIVE: 2,
    PENDING_PAYMENT: 3,
  };

  export const APPLICATIONSTATUS = {
    InManteinance: 0,
    Running: 1,
  };

  export const PERSONTYPE = {
    ADMINISTRATIVE_USER: 0,
    CLIENT: 1,
    BUSINESS_UNIT: 2,
    NO_USER: 3,
    PET: 4,
  };

  export const SHARE_TYPE = {
    BUSINESSUNIT: 0,
    GROUP: 1,
    PROFILE: 2,
    PRODUCT: 3,
    SERVICE: 4,
    POST: 5,
    PET: 6,
  };

  export const ADM_USER_PAYMENT = [3];

  export const CREDENTIALTYPE = {
    EMail: 0,
    Document: 1,
    NickName: 2,
  };

  export const DOCUMENTTYPE = {
    CPF: 1,
    RG: 2,
    CNPJ: 3,
    IE: 4,
  };

  export const CONTACTTYPE = {
    MOBILE: 1,
    LANDLINE: 2,
    MAIL_ADDRESS: 3,
    WHATSAPP: 4,
  };

  export const DOCUMENTS = [
    { value: 1, description: "CPF" },
    { value: 2, description: "RG" },
    { value: 3, description: "CNPJ" },
    { value: 4, description: "IE" },
  ];

  export const CONTACTS = [
    { value: 1, description: "Celular" },
    { value: 2, description: "Telefone Fixo" },
    { value: 3, description: "E-Mail" },
    { value: 4, description: "Whatsapp" },
  ];

  export const MARITALSTATUS = {
    Single: 0,
    Married: 1,
    Divorced: 2,
    Widower: 3,
    Loving: 4,
  };

  export const GENRE = [
    { value: 0, description: "Masculino" },
    { value: 1, description: "Feminino" },
    { value: 2, description: "Prefiro não informar" },
  ];

  export const OfferType = [
    { value: 1, description: "Produtos" },
    { value: 2, description: "Serviços" },
    { value: 3, description: "Ambos" },
  ];

  export const BUSINESS_UNIT_PERSON_TYPE = [
    { value: 1, description: "Sócio/Fundador" },
    { value: 2, description: "Sócio" },
    { value: 3, description: "Colaborador" },
  ];

  export const BUSINESSUNIT_PERSONTYPE = {
    OWNER: 1,
    PARTNER: 2,
    EMPOYEE: 3,
  };

  export const BUSINESSUNIT_PROFILE_OPTIONS = {
    ANALYTICS: 0,
    DESIGN: 1,
    PRINCIPAL_DATA: 2,
    ABOUT_HISTORY: 3,
    PRODUCT_MIX: 4,
    INSERTS: 5,
    REPRESENTATIVES: 6,
    ORDERS: 7,
    SERVICE_MIX: 8,
    PET_WORK: 9,
    ATENDANTS: 10,
    SCHEDULE: 11,
    ADOPET: 12,
    MARKETING_ACTIONS: 13,
    ADMINISTRATIVE_USERS: 14,
    PARAMETERS_CONFIG: 15,
    DELIVERY_CONFIG: 16,
    MEMBERSHIP_PLANS: 17,
    BANKING_DATA: 18,
  };

  export const JOB_CONTRACT_TYPE_TITLE = [
    { id: 0, description: "FreeLancer" },
    { id: 1, description: "Temporário" },
    { id: 2, description: "CLT (Efetivo)" },
  ];

  export const JOB_CONTRACT_TYPE = {
    FREELANCER: 0,
    TEMPORARY: 1,
    CLT: 2,
  };

  export const JOB_CONTRACT_TIME_TITLE = [
    { id: 0, description: "Dia Único" },
    { id: 1, description: "Final de Semana" },
    { id: 2, description: "Indefinido" },
  ];

  export const JOB_CONTRACT_TIME = {
    ONE_DAY: 0,
    WEEKEND: 1,
    UNDEFINED: 2,
  };

  export const JOB_OPPORTUNITY_STATUS_TITLE = [
    { id: 0, description: "Criado" },
    { id: 1, description: "Publicado" },
    { id: 2, description: "Finalizado" },
  ];

  export const JOB_OPPORTUNITY_STATUS = {
    CREATED: 0,
    SHARED: 1,
    FINISHED: 2,
  };

  export const PET_DATING_TAB_TITLE = [
    { id: 0, description: "Pretendentes" },
    { id: 1, description: "Piscadinhas" },
    { id: 2, description: "Preferências" },
    { id: 3, description: "Como Funciona" },
  ];

  export const PET_DATING_TAB_CONTENT = {
    SUITORS: 0,
    WINKS: 1,
    PREFERENCES: 2,
    HOW: 3,
  };

  export const PET_DATING_WINKS_REACTION = {
    REQUESTED: 0,
    MATCH: 1,
    DENY: 2,
    MAYBELATER: 3,
  };

  export const PETWORK_TITLES = [
    { id: 0, description: "Painel de Vagas", icon: "checklist" },
    { id: 1, description: "Publicar nova vaga", icon: "person_add" },
    { id: 2, description: "Avaliações", icon: "stars" },
  ];

  export const PETWORK = {
    PANEL: 0,
    NEW_OPPORTUNITY: 1,
    EVALUATIONS: 2,
  };

  export const BUSINESSUNIT_PROFILE_OPTIONS_TITLES = [
    { id: 0, description: "Analytics" },
    { id: 1, description: "Design" },
    { id: 2, description: "Dados Principais" },
    { id: 3, description: "Sobre" },
    { id: 4, description: "Produtos" },
    { id: 5, description: "Inserções" },
    { id: 6, description: "Representantes" },
    { id: 7, description: "Meus Pedidos" },
    { id: 8, description: "Serviços" },
    { id: 9, description: "PetWork" },
    { id: 10, description: "Atendentes" },
    { id: 11, description: "Agenda" },
    { id: 12, description: "AdoPet" },
    { id: 13, description: "Ações de Marketing" },
    { id: 14, description: "Usuários Administrativos" },
    { id: 15, description: "Configurações de Operação" },
    { id: 16, description: "Delivery" },
    { id: 17, description: "Assinatura" },
    { id: 18, description: "Conta Digital" },
  ];

  export const PET_VETERINARY_RECORD_SUMMARY_TITLES = [
    { id: 0, description: "Perfil", icon: "pets" },
    { id: 1, description: "Prontuário Eletrônico", icon: "monitor_heart" },
    { id: 2, description: "PetNamoro", icon: "favorite" },
    { id: 3, description: "Token de Acesso Ao Animal", icon: "security" },
  ];

  export const PET_VETERINARY_SUMMARY_RECORD = {
    PET_DATA: 0,
    VACCINES: 1,
    PET_DATING: 2,
    SECURITY: 3,
  };

  export const PET_VETERINARY_RECORD_TITLES = [
    { id: 0, description: "Publicações", icon: "perm_media" },
    { id: 1, description: "Perfil", icon: "pets" },
    { id: 2, description: "Prontuário Eletrônico", icon: "monitor_heart" },
    { id: 3, description: "PetNamoro", icon: "favorite" },
    {
      id: 4,
      description: "Rastreamento - Em Breve ;-)",
      icon: "share_location",
    },
  ];

  export const PET_VETERINARY_RECORD = {
    PICTURES: 0,
    PET_DATA: 1,
    VACCINES: 2,
    PET_DATING: 3,
    TRACKER: 4,
  };

  export const PET_MEDICINS_RECORD_TITLES = [
    { id: 0, description: "Vacinas", icon: "vaccines" },
    { id: 1, description: "Vermífugos", icon: "bug_report" },
    { id: 2, description: "Antiparasitário", icon: "pest_control" },
    { id: 3, description: "Consultas & Exames", icon: "monitor_heart" },
  ];

  export const PET_MEDICINS_RECORD = {
    VACCINES: 0,
    VERMIFUGE: 1,
    ANTIPARASITARY: 2,
    VETERINARY_CONSULTATION: 3,
  };

  export const HIRING_EVALUATION_TYPE = {
    PROFESSIONAL: 0,
    ESTABLISHMENT: 1,
  };

  export const REVIEW_STATUS = {
    CREATED: 0,
    EVALUATED: 1,
  };

  export const FLU_VACCINE_VARIANT = [
    { id: 0, description: "Injetável" },
    { id: 1, description: "Intranasal" },
    { id: 2, description: "Oral" },
  ];

  export const PET_MEDICINS_TYPE = {
    VERMIFUGE: 0,
    ANTIPARASITARY: 1,
  };

  export const VETERINARY_CONSULTATION_TYPE = {
    ROUTINE: 0,
    EMERGENCY: 1,
    PERIODICAL: 2,
  };

  export const VETERINARY_CONSULTATION_TITLE = [
    { id: 0, description: "Rotina" },
    { id: 1, description: "Emergência" },
    { id: 2, description: "Periódica" },
  ];

  export const PRODUCTOFFERTYPE = {
    PRODUCT: 1,
    SERVICE: 2,
    BOTH: 3,
  };

  export const CITIES = [
    { id: 1, description: "RO" },
    { id: 2, description: "AC" },
    { id: 3, description: "AM" },
    { id: 4, description: "RR" },
    { id: 5, description: "PA" },
    { id: 6, description: "AP" },
    { id: 7, description: "TO" },
    { id: 8, description: "MA" },
    { id: 9, description: "PI" },
    { id: 10, description: "CE" },
    { id: 11, description: "RN" },
    { id: 12, description: "PB" },
    { id: 13, description: "PE" },
    { id: 14, description: "AL" },
    { id: 15, description: "SE" },
    { id: 16, description: "BA" },
    { id: 17, description: "MG" },
    { id: 18, description: "ES" },
    { id: 19, description: "RJ" },
    { id: 20, description: "SP" },
    { id: 21, description: "PR" },
    { id: 22, description: "SC" },
    { id: 23, description: "RS" },
    { id: 24, description: "MS" },
    { id: 25, description: "MT" },
    { id: 26, description: "GO" },
    { id: 27, description: "DF" },
  ];

  export const COUNTRIES = [{ id: 1, description: "BRASIL" }];

  export const SEARCHRESPONSE = {
    TYPE: {
      USER: 0,
      PERSON: 1,
    },
  };

  export const NOTIFICATIONTYPE = {
    NONE: 0,
    INTERNAL: 1,
    EMAIL: 2,
    SMS: 3,
    WHATSAPP: 4,
    PUSH: 5,
    ALL: 6,
  };

  export const NOTIFICATION_STATUS = {
    UNREAD: 0,
    READ: 1,
    ALL: 2,
  };

  export const PETTYPE = [
    { value: -1, description: "" },
    { value: 0, description: "Cachorro" },
    { value: 1, description: "Gato" },
    { value: 2, description: "Pássaro" },
    // { value: 3, description: "Hamster" },
    { value: 4, description: "Tartaruga" },
  ];

  export const PET_GENRE = [
    { value: -1, description: "" },
    { value: 0, description: "Macho" },
    { value: 1, description: "Fêmea" },
  ];
  export const PET_SIZE = [
    { value: -1, description: "" },
    { value: 0, description: "Pequeno" },
    { value: 1, description: "Médio" },
    { value: 2, description: "Grande" },
  ];

  export const CASTRATED = [
    { value: false, description: "Não" },
    { value: true, description: "Sim" },
  ];

  export const IMAGETYPE = {
    TYPE: {
      PRODUCT: 0,
      BUSINESSUNITLOGO: 1,
      PET: 2,
      PROFILE: 3,
      GROUP: 4,
      PUBLICATION: 5,
      PHOTOALBUM: 6,
      SERVICE: 7,
      DEFAULT: 8,
      EVALUATION: 9,
      HEADERSTORE: 10,
    },
  };

  export const IMAGETRANSACTIONTYPE = {
    TYPE: {
      UPDATE: 0,
      SELECTONLY: 1,
    },
  };

  export const GROUPPERSONTYPE = {
    TYPE: {
      OWNER: 0,
      ADMINISTRATIVEMEMBER: 1,
      MEMBER: 2,
    },
  };

  export const EXPOSURELEVELLIST = {
    LEVEL: {
      PUBLIC: 0,
      PRIVATE: 1,
    },
  };

  export const EXPOSURELEVEL = [
    { value: 0, description: "Público" },
    { value: 1, description: "Privado" },
  ];

  export const GROUP_TYPE = [
    { id: 0, description: "Interação - Conteúdos diversos" },
    {
      id: 1,
      description: "Profissional - Publicação de vagas e oportunidades",
    },
  ];

  export const GROUPTYPES = {
    INTERACTIONS: 0,
    PROFESSIONAL: 1,
  };

  export const POST = {
    TYPE: {
      PHOTO: 0,
      VIDEO: 1,
      EVENT: 2,
      ARTICLE: 3,
      JOB_OPPORTUNITY: 4,
    },
  };

  export const REACTION = {
    TYPE: {
      LIKE: 0,
      LOVE: 1,
      CONGRATS: 2,
      SMILE: 3,
      SAD: 4,
      HATE: 5,
    },
  };

  export const CATEGORIES = {
    LEVEL: {
      CATEGORIE: 0,
      SUBCATEGORIE_LEVEL1: 1,
      SUBCATEGORIE_LEVEL2: 2,
      SUBCATEGORIE_LEVEL3: 3,
    },
  };

  export const PRODUCTLISTTYPEOPTIONS = [
    { value: 0, description: "Genérico" },
    { value: 1, description: "Fazem sucesso entre os pets" },
    { value: 2, description: "Baseado nas suas últimas pesquisas" },
    { value: 3, description: "Achamos que podem te interessar" },
  ];

  export const PRODUCTLISTTYPE = {
    SEVERAL: 0,
    FEATURED: 1,
    SEARCHED: 2,
    SUGGESTION: 3,
  };

  export const SEARCH_RESULTS = [
    { value: 0, description: "Produtos" },
    { value: 1, description: "Serviços" },
    { value: 2, description: "Lojistas" },
    { value: 3, description: "Usuários" },
    { value: 4, description: "Grupos" },
    { value: 5, description: "Pets" },
  ];

  export const SEARCH_RESULTS_CONTENT = {
    PRODUCT: 0,
    SERVICE: 1,
    SHOPKEEPER: 2,
    USER: 3,
    GROUP: 4,
    PET: 5,
  };
  export const SEARCHTYPE = {
    PRODUCT: 0,
    SERVICE: 1,
    SHOPKEEPER_PRODUCT: 2,
    USER: 3,
    GROUP: 4,
    SHOPKEEPER: 5,
    PRODUCTS_BY_CATEGORY: 6,
    PET: 7,
    JOB_OPPORTUNITY: 8,
    JOB_CANDIDATE: 9,
    BRAND: 10,
  };

  export const PARAMETERS_TYPES_VALUES = [
    { value: 0, description: "Selecione um tipo de parâmetro" },
    { value: 1, description: "Texto simples" },
    { value: 2, description: "Verdadeiro - Falso" },
    { value: 3, description: "Lista de Opções" },
    { value: 4, description: "De - Até" },
  ];

  export const PARAMETERS_TYPES = {
    SIMPLE_TEXT: 1,
    TRUE_OR_FALSE: 2,
    OPTIONS_LIST: 3,
    FROM_TO: 4,
  };

  export const MULTIPLE_OPTIONS = [
    { value: false, description: "Não" },
    { value: true, description: "Sim" },
  ];

  export const PARAMETER_INTERNAL_CODE = [
    { value: 0, description: "Selecione o código interno do parâmetro" },
    { value: 1, description: "Company_Slogan" },
    { value: 2, description: "Delivery_System" },
    { value: 3, description: "Week_Days" },
    { value: 4, description: "Week_Days_Opening_Hours" },
    { value: 5, description: "Weekend_Days" },
    { value: 6, description: "Saturday_Opening_Hours" },
    { value: 7, description: "Sunday_Opening_Hours" },
    { value: 8, description: "Delivery_Distance" },
    { value: 9, description: "Allow_Service_Scheduling" },
  ];

  export const PARAMETER_INTERNAL_CODE_VALUE = {
    COMPANY_SLOGAN: 1,
    DELIVERY_SYSTEM: 2,
    WEEK_DAYS: 3,
    WEEK_DAYS_OPENING_HOURS: 4,
    WEEKEND_DAYS: 5,
    SATURDAY_OPENING_HOURS: 6,
    SUNDAY_OPENING_HOURS: 7,
    DELIVERY_DISTANCE: 8,
    ALLOW_SERVICE_SCHEDULING: 9,
  };

  export const PARAMETER_VALUE_TYPE = [
    { value: 0, description: "Selecione o tipo de valores do parâmetro" },
    { value: 1, description: "Números" },
    { value: 2, description: "Horas" },
  ];

  export const NAVIGATION_TYPE = {
    USER: 0,
    COMPANY: 1,
    ANONYMOUS: 2,
  };

  export const VISUALIZATION_TYPES = {
    LIST: 0,
    MAP: 1,
  };

  export const DELIVERY_SYSTEM = [
    { value: 0, description: "Entrega Própria" },
    { value: 1, description: "Entrega PetAoLado.com" },
    { value: 2, description: "Retirada em loja" }
  ];

  export const DELIVERYSYSTEM = {
    OWNDELIVERYSYSTEM: 0,
    PLATFORMDELIVERYSYSTEM: 1,
    PICKUPINSTORE: 2
  };

  export const INSTALMENTS = [
    { value: 1, description: "1x - Parcela única" },
    { value: 2, description: "2x - Duas parcelas" },
    { value: 3, description: "3x - Três parcelas" },
    { value: 4, description: "4x - Quatro parcelas" },
    { value: 5, description: "5x - Cinco parcelas" },
    { value: 6, description: "6x - Seis parcelas" },
    { value: 7, description: "7x - Ste parcelas" },
    { value: 8, description: "8x - Oito parcelas" },
    { value: 9, description: "9x - Nove parcelas" },
    { value: 10, description: "10x - Dez parcelas" },
    { value: 11, description: "11x - Onze parcelas" },
    { value: 12, description: "12x - Doze parcelas" },
  ];

  export const INSTALMENTS_WITHOUT_FEE = [
    { value: 0, description: "Não Aplica" },
    { value: 1, description: "1x - Uma parcela" },
    { value: 2, description: "2x - Duas parcelas" },
    { value: 3, description: "3x - Três parcelas" },
    { value: 4, description: "4x - Quatro parcelas" },
    { value: 5, description: "5x - Cinco parcelas" },
    { value: 6, description: "6x - Seis parcelas" },
    { value: 7, description: "7x - Ste parcelas" },
    { value: 8, description: "8x - Oito parcelas" },
    { value: 9, description: "9x - Nove parcelas" },
    { value: 10, description: "10x - Dez parcelas" },
    { value: 11, description: "11x - Onze parcelas" },
    { value: 12, description: "12x - Doze parcelas" },
  ];

  export const CHARGE_TYPE = [
    { value: 0, description: "Valor Fixo" },
    { value: 1, description: "Valor por Km" },
  ];

  export const CHARGETYPE = {
    FIXED_VALUE: 0,
    KM_VALUE: 1
  };


  export const CONTACT_SUBJECT = [
    { id: 1, description: "Tenho uma sugestão" },
    { id: 2, description: "Tenho um elogio" },
    { id: 3, description: "Tenho um problema" },
  ];

  export const TYPEUSER = {
    TEAM_BU: 0,
    USER: 1,
  };

  export const ORDER_STATUS = [
    { value: 0, description: "Aguardando Emiss.", color: "orange" },
    { value: 1, description: "Aguardando Conf.", color: "orange" },
    { value: 2, description: "Pagto Pendente.", color: "orange" },
    { value: 3, description: "Em Preparação", color: "green" },
    { value: 4, description: "Negado", color: "red" },
    { value: 5, description: "À Caminho", color: "green" },
    { value: 6, description: "Entregue", color: "green" },
    { value: 7, description: "Cancelado", color: "red" },
  ];

  export const ORDERSTATUS = {
    CREATED: 0,
    ISSUED: 1,
    WAITING_PAYMENT: 2,
    CONFIRMED: 3,
    DISAPPROVED: 4,
    ONWAY: 5,
    DELIVERED: 6,
    CANCELED: 7,
  };

  export const PRODUCTSUGESTIONSTATUS = {
    Created: 0,
    RegistrationProcess: 1,
    Rejected: 2,
    Registered: 3,
  };

  export const PRODUCTSUGESTIONSTATUS_DESCRIPTION = [
    { value: 0, description: "Cadastro Pendente" },
    { value: 1, description: "Cadastro em Andamento" },
    { value: 2, description: "Cadastro não Efetuado" },
    { value: 3, description: "Cadastro Efetuado" },
  ];

  export const BUSINESSUNIT_INTERACTION_TYPE = {
    BUSINESSUNITACCESS: 0,
    BUSINESSUNITCONTACT: 1,
    BUSINESSUNITLINKSHARE: 2,
    PRODUCTACCESS: 3,
    SERVICEACCESS: 4,
    ADOPETACCESS: 5,
    CONTENTVISUALIZATION: 6,
    SHOPCARTPRODUCTADDED: 7,
    PRODUCTBUY: 8,
    SERVICESCHEDULE: 9,
    ADOPETINTEREST: 10,
    CONTENTSHARE: 11,
    ADOPETCONFIRMED: 12,
    ALL: 99,
  };

  export const BUSINESSUNIT_INTERACTION_TYPE_DESCRIPTION = [
    { value: 0, description: "Acesso à Loja" },
    { value: 1, description: "Falou com a empresa" },
    { value: 2, description: "Compartilhou o endereço da empresa" },
    { value: 3, description: "Acessou um produto da empresa" },
    { value: 4, description: "Acessou um serviço da empresa" },
    { value: 5, description: "Acessou um animal da empresa" },
    { value: 6, description: "Visualizou um conteúdo postado" },
    {
      value: 7,
      description: "Adicionou um produto da empresa no carrinho de compras",
    },
    { value: 8, description: "Comprou um produto da empresa" },
    { value: 9, description: "Agendou/Solicitou um serviço" },
    { value: 10, description: "Se interessou por um animal da empresa" },
    { value: 11, description: "Compartilhou um conteúdo da empresa" },
    { description: "", value: 99 },
  ];

  export const TIMELINE_TYPE = {
    SUGESTIONS: 0,
    FOLLOWED: 1,
    USER: 2,
    GROUP: 3,
    PUBLIC_USER: 4,
  };

  export const MEMBERSHIP_PLAN = {
    SHOPKEEPER: 1,
    PROVIDER: 2,
    ADOPET: 3,
  };

  export const MARKETPET_TABS = {
    PRODUCTS: 0,
    SERVICES: 1,
    ADOPET: 2,
  };

  export const PET_WORK_TITLES = [
    { id: 0, description: "Vagas" },
    { id: 1, description: "Minhas candidaturas" },
    { id: 2, description: "Convites Recebidos" },
    { id: 3, description: "Contratações" },
    { id: 4, description: "Avaliações" },
  ];

  export const PET_WORK = {
    JOB_OPPORTUNITY: 0,
    APPLICATIONS: 1,
    INVITES: 2,
    HIRINGS: 3,
    EVALUATIONS: 4,
  };

  export const MONTHS = [
    { value: 1, description: "Janeiro" },
    { value: 2, description: "Fevereiro" },
    { value: 3, description: "Março" },
    { value: 4, description: "Abril" },
    { value: 5, description: "Maio" },
    { value: 6, description: "Junho" },
    { value: 7, description: "Julho" },
    { value: 8, description: "Agosto" },
    { value: 9, description: "Setembro" },
    { value: 10, description: "Outubro" },
    { value: 11, description: "Novembro" },
    { value: 12, description: "Dezembro" },
  ];

  export const CREDIT_CARD_YEARS = [
    { value: 24, description: "2024" },
    { value: 25, description: "2025" },
    { value: 26, description: "2026" },
    { value: 27, description: "2027" },
    { value: 28, description: "2028" },
    { value: 29, description: "2029" },
    { value: 30, description: "2030" },
    { value: 31, description: "2031" },
    { value: 32, description: "2032" },
    { value: 33, description: "2033" },
    { value: 34, description: "2034" },
    { value: 35, description: "2035" },
  ];

  export const JOB_APPLYING_STATUS = {
    CREATED: 0,
    WAITINGRESPONSE: 1,
    HIRED: 2,
    NOTHIRED: 3,
  };

  export const JOB_APPLYING_STATUS_TITLES = [
    { id: 0, description: "Enviada" },
    { id: 1, description: "Aguardando Resposta" },
    { id: 2, description: "Contratado" },
    { id: 2, description: "Não contratado" },
  ];

  export const JOB_APPLYING_INVITE_STATUS = {
    CREATED: 0,
    ACCEPTED: 1,
    REJECTED: 2,
  };

  export const PAYMENT_TYPE = {
    CREDITCARD: 0,
    PIX: 1,
    PAYMENT_SLIP: 2,
  };

  export const SPLIT_PARTICIPANT_TYPE = {
    BUSINESS_UNIT: 0,
    DELIVERY: 1,
    PLATFORM: 2,
  };

  export const SPONSORED_CONTENT_STATUS = {
    AWAITING_PAYMENT: 0,
    PAID: 1,
    EXPIRED: 2,
  };
}

export namespace Constants.DefaultImages {
  export const DEFAULT_BUSINESS_UNIT_IMAGE =
    "https://res.cloudinary.com/petaomeuladocombr/image/upload/v1686937778/petaomeulado/defaultImages/gvwyn86ahhied4ulywwx.png";
}
